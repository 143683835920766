.logout-popup {
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 400px;
}

.logout-popup button{
width: 90px;
margin-left: 10px;
margin-right: 10px;
}
button.swal2-cancel.cancelbtn.swal2-styled {
background-color: whitesmoke;
color: #000084;
border:3px solid #0d0ddf;
height: 40px;
font-weight: 30px;
}
button.swal2-confirm.confirmbtn.swal2-styled {
  background: linear-gradient(90deg, #000084 0%, #000084 86.04%);
  color:  whitesmoke;
  border:3px solid  #000084;
  height: 40px;
  font-weight: 30px;
  }