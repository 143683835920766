.card {
  border-radius: 10px !important;
  /* border: solid 2px #fed561;
  border-width: 1px 3px 3px 1px; */
  background-color: #f2f2f2 !important;
  box-shadow: none !important;
}

.bubbleBox:hover {
  background-color: #f2f2f2;
}

.cardTitleCss {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 15px;
  font-weight: 900;
  /* color: #af9243; */
}

.cardContentCss {
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 800;
  color: #000000;
}

.dashboardTableCss {
  justify-content: right;
  align-items: right;
  display: flex;
  cursor: pointer;
}

.paddingOpenEnded{
  padding: 10px;
}
.paddingCloseEnded{
  padding: 20px;
}