.header {
  margin: 5px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}
.title {
  font-size: 18px;
  font-weight: 900;
  display: inline-block;
  vertical-align: middle;
  padding-top: 1rem;
  padding-left: 0.5rem;
  font-family: "Lato";
}

/* .search { */
/* overflow: hidden; */
/* width: 150px; */
/* height: 30px; */
/* display: flex; */
/* justify-content: center; */
/* position: relative; */
/* right: 30px; */
/* float: right; */
/* border: 1px solid black; */
/* padding: 4px 10px; */
/* border-radius: 18px; */
/* margin-bottom: 8px; */
/* outline: none; */
/* } */

.catSearch {
  overflow: hidden;
  width: 150px;
  height: 30px;
  display: flex;
  justify-content: center;
  position: relative;
  float: right;
  border: 1px solid black;
  padding: 4px 10px;
  border-radius: 18px;
  margin-bottom: 8px;
  margin-top: 20px;
  margin-left: 10px;
  /* padding-right: -20px; */
  outline: none;
}

/* .search > input {
  background-color: transparent;
  border-bottom: 1px solid white !important;
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
  border-bottom-color: white !important;
} */
.column {
  background-color: #333333;
  border-radius: 0;
  color: white;
  font-weight: bold;
  font-size: 8px;
  border-radius: 0;
}

.column :nth-child(n) {
  color: white;
}
.tbl {
  border: 2px solid black;

  font-size: 20px;
  display: flex;
}
.downloadBtn {
  /* margin: 10px 0px; */
  display: flex;
  justify-content: space-between;
  margin-left: 200px;
  margin-right: 200px;
  margin-top: 10px;
}
.btncss {
  /* padding: 1.4em 3em; */
  height: 2.5rem;
  width: 10rem;
  font-size: 14px !important;
  font-weight: 80px !important;
  background: linear-gradient(90deg, #000084 0%, #000084 86.04%);
  text-transform: none !important;
  border: none;
  border-radius: 30px !important;
  margin: 2px;
  cursor: pointer;
  font-family: "Lato";
  color: white ;
}
.btncssProfile {
  /* padding: 1.4em 3em; */
  height: 2.5rem;
  width: 10rem;
  font-size: 14px !important;
  font-weight: 80px !important;
  background: linear-gradient(90deg, #337fc9 0%, #2057a6 86.04%);
  text-transform: none !important;
  border: none;
  border-radius: 30px !important;
  margin: 2px;
  cursor: pointer;
  font-family: "Lato";
  color: white !important;
}
.btncss-disable {
  /* padding: 1.4em 3em; */
  pointer-events: none;
  cursor: not-allowed !important;
  height: 2.5rem;
  width: 10rem;
  font-size: 14px !important;
  font-weight: 80px !important;
  background: linear-gradient(93.3deg, #517cba 22.4%, #517cba 100%);
  /* background-color: #517cba; */
  text-transform: none !important;
  border: none;
  border-radius: 30px !important;
  margin: 2px;
  font-family: "Lato";
  /* opacity: .7 !important; */
  color: white !important;
  opacity: 0.5 !important;
}
.headRow {
  background-color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  border: 4px solid #333333;
  width: 20000rem;
}

.headRow :nth-child(n) {
  border-left: 20px;
}

/* used in Decommissioned */
.btncssDecommission {
  /* padding: 1.4em 3em; */
  height: 2.5rem;
  width: 10rem;
  font-size: 14px;
  font-weight: bold;
  background: linear-gradient(93.3deg, #d73d3d 22.4%, #ca0505 100%);

  border: none;
  border-radius: 30px;
  margin: 2px;
  cursor: pointer;
  font-family: "Lato";
}
.loginButton {
  /* padding: 1.4em 3em; */
  height: 2rem;
  width: 10rem;
  font-size: 14px;
  font-weight: bold;
  background: linear-gradient(90deg, #000084 0%, #000084 86.04%);
  color: white !important;
  border: none;
  border-radius: 30px;
  margin: 2px;
  cursor: pointer;
  font-family: "Lato" !important;
}
.login-disabled{
  cursor: not-allowed !important;
  pointer-events: none;
  height: 2rem;
  width: 10rem;
  font-size: 14px;
  font-weight: bold;
  background: linear-gradient(90deg, #000084 0%, #000084 86.04%);
  color: white !important;
  border: none;
  border-radius: 30px;
  margin: 2px;
  font-family: "Lato" !important;
  opacity: 0.3;
}

