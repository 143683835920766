.card {
  border-radius: 10px !important;
  /* border: solid 2px #fed561; */
  /* border-width: 1px 3px 3px 1px; */
  background-color: #f2f2f2 !important;
  box-shadow: none !important;
}

.bubbleBox:hover {
  background-color: #f2f2f2;
  cursor: pointer;
}

.cardTitleCss {
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 900;
  /* color: #af9243; */
  height: 3.5rem !important;
  padding-bottom: 30px;
}

.cardNotificationCss {
  display: flex;
  justify-content: end;
  margin-right: 10px;
  height: 5px;
}

/* .cardContentCss {
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 800;
  color: #000000;
} */

/* .cardGrid {
  display: flex;
  flex-direction: column;
} */

.row1 {
  display: flex;
  flex-direction: row;
}

.row2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.row3 {
  display: flex;
  flex-direction: row;
}

.singleCard {
  margin: 1rem !important;
}

.dot {
  height: 30px;
  width: 30px;
  background-color: #2057A6;
  color:white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-weight: 700; */
}

.noInvestmentSecondLineCss {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -50px;
}

.loaderCss {
  position: absolute;
  top: 50%;
  left: 50%;
}
